import React from "react"

import "../css/pages/mentions-legales.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";

const PolitiqueDeConfidentialite = () => (
  <Layout>
    <SEO title="Politique de confidentialité" />
    <div className={"page mentions-legales politique-de-confidentialite"}>
        <div className={"header"}>
            <h1 className={"title"}>Politique de confidentialité</h1>
        </div>
      <Section title={"Cookies"}>
          <p>Nous utilisons des cookies afin de mesurer notre audience. Cela nous permet principalement de connaitre le nombre de visiteur sur notre site internet.</p>

          <p>De plus, nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont des données qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil.</p>

          <p>En cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement. Si vous supprimez ou désactivez nos cookies, vous pourriez rencontrer quelques changements visuels et fonctionnels.</p>
      </Section>

      <Section title={"Utilisation et transmission de vos données personnelles"}>
          <h3>Durées de stockage de vos données</h3>
          <p>Vos informations de contact saisies dans le formulaire de prise de rendez-vous sont converservées indéfiniment.</p>

          <h3>Les droits que vous avez sur vos données</h3>
          <p>Vous pouvez demander à recevoir un fichier contenant toutes les données personnelles que nous possédons à votre sujet, incluant celles que vous nous avez fournies. Vous pouvez également demander la suppression des données personnelles vous concernant. Cela ne prend pas en compte les données stockées à des fins administratives, légales ou pour des raisons de sécurité. Pour cela, <Link to={"/#contact"}>contactez-nous</Link>.</p>

          <h3>Transmission de vos données personnelles</h3>
          <p>Nous utilisons un service tiers pour vous contacter par mail lors de la prise de rendez-vous via le formulaire.</p>

          <h3>Informations de contact</h3>
          <p>Pour exercer votre droit sur vos données, vous pouvez nous contacter à l’adresse suivante : <a href="mailto:jeanbaptiste.prudhomme@sfr.fr">jeanbaptiste.prudhomme@sfr.fr</a>.</p>
      </Section>

        <Section title={"Informations supplémentaires"}>
            <h3>Comment nous protégeons vos données</h3>
            <p>Les données transmises par le biais de votre navigateur sont sécurisées à l’aide du protocole SSL (HTTPS).</p>

            <h3>Procédures mises en œuvre en cas de fuite de données</h3>
            <p>En cas de fuite de données, nous nous engageons à en faire part aux autorités compétentes (CNIL) ainsi qu’aux personnes concernées.</p>

            <h3>Opérations de marketing automatisé et/ou de profilage réalisées à l’aide des données personnelles</h3>
            <p>Nous collectons des données anonymement à l’aide d’un outil de mesure d’audience.</p>
        </Section>

        <Section title={"Paramétrage des cookies"}>
            <p>Si vous paramétrez les cookies que nous utilisons sur notre site, voici la procédure à suivre en fonction de votre navigateur :</p>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en" target="_blank" rel="noopener">Chrome</a></li>
                <li><a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies" target="_blank" rel="noopener">Firefox</a></li>
                <li><a href="http://www.apple.com/support/?path=Safari/3.0/fr/9277.html" target="_blank" rel="noopener">Safari</a></li>
                <li><a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank" rel="noopener">Opera</a></li>
                <li><a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies" target="_blank" rel="noopener">Internet Explorer</a></li>
            </ul>

            <p>En modifiant les paramétres de votre navigateur, les cookies sur ce site peuvent être modifiés et/ou supprimés. Cette opération entraînera cependant la modification et/ou la suppression de tous les cookies utilisés par le navigateur, y compris ceux employés par d’autres sites web, ce qui peut conduire à l'altération ou la perte de certains réglages ou informations et à rendre difficile la navigation sur le site web.</p>
        </Section>

        <Section title={"Désactiver la mesure d'audience anonyme"}>
            Nous utilisons un outil qui nous permet de connaître le nombre de visiteurs sur notre site internet. Ainsi, nous pouvons adapter notre contenu en fonction de notre audience. Si vous souhaitez désactiver cette fonctionnalité sur notre site, cliquez-ici :
        </Section>
    </div>
  </Layout>
)

export default PolitiqueDeConfidentialite
